.cronjobs .mouseover-container {
  position: absolute;
  z-index: 200;
  border: 3px solid;
  padding: 5px;
  box-shadow: 3px 3px 1px 1px rgba(0, 0, 0, 0.45);
  background-color: white;
  overflow: auto;
}

.cronjobs .job-instance .job-state {
  display: inline-block;
  border: 2px solid;
  padding: 2px;
  text-transform: uppercase;
  margin-left: 14%;
}
.cronjobs .job-instance .job-state.failed {
  border-color: #bd5c58;
  color: #bd5c58;
}

@keyframes job-status-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.cronjobs .job-instance .job-state.active {
  border-color: #378ec8;
  color: #378ec8;
  animation: job-status-animation 1.8s infinite;
}

.cronjobs .job-instance .job-state.unknown {
  border-color: #4d5052;
  color: #4d5052;
}
