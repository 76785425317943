.app {
  text-align: left;
}

.app-body {
  background-color: #2e3d6b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: 400;
  font-size: 21px;
}

.ui.column.grid.deploys-text {
  font-weight: 200;
  margin-left: 1vw;
  font-size: 16px;
}

.column.deployment-revision {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.column.deployment-revision:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.ui.column.grid.deploys-text-header {
  font-weight: 400;
  margin-left: 1vw;
  font-size: 16px;
}

.ui.table.instances {
  margin-bottom: 20px;
}

.a-d-v-box {
  background-color: #ffffff;
  margin-top: 20px;
  width: 70vw;
  border-radius: 6px;
}

.p.box-header {
  margin-top: 1.5vh;
  margin-left: 1vw;
}

.p.box-header .details-section-title {
  font-size: 1.2rem;
  margin-right: 2vw;
}

.circle {
  height: 30px;
  width: 30px;
  background-color: #eeeeee;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  top: 7px;
  border-style: solid;
  border-width: thin;
  border-color: grey;
}

.number {
  text-align: center;
  position: relative;
  top: 6px;
  font-weight: 600;
}

.no-link {
  color: black;
}

.no-link:hover {
  color: black;
}

.fake-link {
  color: black;
}

.fake-link:hover {
  color: black;
  cursor: pointer;
}

.detail-action-button {
  font-size: 0.6875rem;
  color: #0178cc;
  margin-right: 1vw;
  border: solid #0178cc;
  border-width: 0 0 1px;
  padding: 2px 3px;
  cursor: pointer;
}

.detail-action-button.divider {
  color: grey;
  border: none;
  cursor: none;
}
.detail-action-button.divider::before {
  content: '|';
}
