.ui.container.no-data-box {
  background-color: #fdfdfd;
  color: grey;
  border-radius: 6px;
  font-size: 16px;
  padding: 15px;
  text-align: center;
  border: rgb(224, 224, 224);
  border-width: 1px;
  border-style: solid;
  font-weight: 400;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
