.app-bar-header {
  background-color: #ffffff;
  min-width: 65vw;
  color: #000000;
  box-shadow: 5px 4px 4px 0px #000000;
  margin-top: 3vh !important;
}

.ui.button.app-bar-header-dropdown {
  background-color: #ffffff;
}

.ui.multiple.search.dropdown.ui.button.app-bar-header-dropdown.large.text {
  padding: 0;
  margin-top: -0.3rem;
  margin-left: -0.3rem;
}

.ui.multiple.search.dropdown.ui.button.app-bar-header-dropdown.large.text
  > .text {
  color: #666;
}

.ui.multiple.search.dropdown.ui.button.app-bar-header-dropdown.large.text
  > input.search {
  line-height: 0;
}

.ui.button.app-bar-header-dropdown:not(.icon)
  > .caret.down.icon:not(.button):not(.dropdown) {
  margin-left: 65px;
  padding-top: 10px;
  color: #666;
}
