.login-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    background-color: black;
}

/* short of uninstalling the semantic-ui or dieter-ui css package, there's no easy weay to control the css without important */

.login-card {
    border-color: black !important;
    background-color: black !important;
}

.login-text {
    color: white !important;
    font-size: 40px !important;
}
