.historical-status-timestamp {
  color: grey;
  font-size: 13px;
  font-weight: 400;
  float: right;
  margin-right: 1vw;
  margin-top: 1vh;
}

.calendar-wrapper {
  margin-bottom: 2.5vh;
  width: 100%;
}

.ui.button.status-button {
  margin-left: 1vw;
}

.status-reset {
  color: #025999;
  margin-left: 0.5vw;
  font-size: 12px;
  margin-top: 1vh;
  border-radius: 6px;
  border: 1px solid;
  padding: 6px;
  cursor: pointer;
}

.status-reset:hover {
  background-color: #ecf3ff;
}

.historical-status-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0.75vw 1vw 0.5vw 1vw;
}

.historical-status-container .app-status {
  width: 12px;
  height: 58px;
  border-radius: 6px;
}

.historical-status-container .app-status.down {
  background-color: #bd5c58;
}

.historical-status-container .app-status.up {
  background-color: #88cf67;
}

.historical-status-container .app-status.degraded {
  background-color: orange;
}

.historical-status-container .mouseover-container {
  position: absolute;
  z-index: 200;
  border: 3px solid;
  padding: 5px;
  box-shadow: 3px 3px 1px 1px rgba(0, 0, 0, 0.45);
  background-color: white;
  overflow: auto;
}

.historical-status-container .mouseover-container .app-service {
  padding-top: 5px;
}

.historical-status-container .mouseover-container .app-service:first-child() {
  padding-top: 0px;
}

.historical-status-container .mouseover-container .app-service .status {
  border: 2px solid;
  border-color: black;
  margin: 0 5px;
  padding: 2px;
  color: black;
  max-width: 80px;
  text-align: center;
  font-size: small;
  text-transform: capitalize;
}

.historical-status-container .mouseover-container .app-service .instances {
  font-family: 'Courier New', Courier, monospace;
}

.historical-status-container .mouseover-container {
  font-size: 1rem;
}

.historical-status-container .mouseover-container .timestamp {
  font-size: 0.8rem;
  font-weight: 200;
  color: #aaaaaa;
}

.historical-status-container .mouseover-container ul {
  list-style: disc outside none;
  margin-block-start: 0;
  padding-inline-start: 20px;
}

.historical-status-container .mouseover-container.down,
.historical-status-container .mouseover-container .app-service .status.down {
  border-color: #bd5c58;
}

.historical-status-container .mouseover-container.up,
.historical-status-container .mouseover-container .app-service .status.up {
  border-color: #88cf67;
}

.historical-status-container .mouseover-container.degraded,
.historical-status-container
  .mouseover-container
  .app-service
  .status.degraded {
  border-color: orange;
}

/* This overrides a similarly !important flag in the Dieter component */
.ui.popup.resize {
  min-width: 340px !important;
}
