:root {
  --concierge-button-width: 140px;
  --concierge-button-height: 60px;
}

.page-header {
  min-height: 200px;
  border-radius: 0px;
  height: 0px;
  background-color: #2e3d6b;
}

.env-var-container {
  width: 100%;
  text-align: center;
}

.side-button {
  background-color: #2e3d6b;
  height: 46px;
  width: 70%;
  border-radius: 0px 6px 6px 0px;
  color: white;
  text-align: left;
  line-height: 46px;
  margin-top: 4px;
  padding-left: 35px;
  font-weight: 600;
}

.side-button.selected {
  background-color: white;
  color: black;
}

.side-button:hover {
  background-color: white;
  color: black;
}

.concierge-button {
  display: block;
  padding-top: 0.5em;
  font-weight: 600;
  margin: auto;
  text-align: center;
  height: 80%;
  width: 90%;
  border-radius: 6px;
  background-color: white;
}

.concierge-button.outer {
  top: 10.8%;
  left: 25%;
  position: absolute;
  width: 45%;
  height: 50px;
  border-radius: 6px;
  background-color: #2a2a2a;
}

.side-panel {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #2e3d6b;
  border-radius: 6px;
}

.side-panel-title {
  font-size: larger;
  font-weight: 600;
  color: white;
  margin-top: 18px;
  padding-left: 20px;
  padding-top: 5px;
  background-color: #2A2A2A;
  border-radius: 0px 6px 0px 0px;
}

.side-panel-extra-info {
  font-size: small;
  font-weight: 400;
  color: white;
  padding-left: 20px;
  padding-bottom: 5px;
  background-color: #2A2A2A;
  border-radius: 0px 0px 6px 0px;
}
