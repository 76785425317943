.error-oval {
  width: 70px;
  bottom: -5px;
  height: 180px;
  background: #e1ebfe;
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
  transform: rotate(0.75turn);
  box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
}

.errortext {
  /* always an admission of failure */
  color: black;
  font-size: 2rem !important;
  position: absolute;
  transform: rotate(0.25turn);
  right: -0.3rem;
  top: 100px;
  font-weight: 600;
}

.ui.image.exclamation {
  position: absolute;
  top: 0.6rem;
  right: 0.7rem;
  max-width: 70%;
  transform: rotate(0.25turn);
}
.ui.modal.modal-mod {
  background: transparent;
  box-shadow: none;
}

.ui.container.error {
  position: relative;
  width: 600px;
  height: 170px;
  background: white;
  border-radius: 6px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.ui.image.times-svg {
  bottom: 245px;
  left: 245px;
  transform: scale(0.05);
}

.ui.image.times-svg:hover {
  transform: scale(0.06);
}
.error-string {
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
}

.error-string-box {
  top: 60px;
  left: 190px;
  position: absolute;
  width: 50%;
  height: 30%;
}
