.modal-box {
  background-color: white;
}

.ui.checkbox.modal-checkbox {
  padding-left: 1em;
  display: flex;
}

.ui.button.modal-button {
  display: flex;
  float: right;
}

.error {
  color: red;
}

.warning {
  color: orange;
}
