.ui.text.secondary > .status {
  margin-top: 0.2rem;
}

.status {
  border: 2px solid;
  border-color: black;
  color: black;
  max-width: 80px;
  text-align: center;
  font-size: small;
}

.status.up {
  color: green;
  border-color: green;
}

.status.down {
  color: red;
  border-color: red;
}

.status.degraded {
  color: orange;
  border-color: orange;
}

.status.unknown {
  color: grey;
  border-color: grey;
}

.status.error {
  color: grey;
  border-color: grey;
}
