.service-name {
  font-weight: 600;
  text-transform: capitalize;
  font-size: larger;
  background-color: #2e3d6b;
  color: whitesmoke;
  border-radius: 6px;
}

.ui.raised.card.card-resize {
  max-width: 32.1%;
  width: 32.1%;
  border-radius: 6px;
}

/* This overrides a similarly !important flag in the Dieter component */
.ui.popup.alert-popup-resize {
  min-width: 340px !important;
}

.pointer {
  font-weight: 700;
  color: grey;
}

.text-expand {
  font-weight: 400;
  color: black;
  display: block;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-expand:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
