.refresh-icon {
  color: #000;
  position: absolute;
  margin-left: 4px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-top: solid 1px currentColor;
  border-bottom: solid 1px currentColor;
  border-left: solid 1px transparent;
  border-right: solid 1px currentColor;
}
.refresh-icon:before {
  content: '';
  position: absolute;
  left: 1px;
  top: 10px;
  width: 3px;
  height: 3px;
  border-top: solid 1px currentColor;
  border-left: solid 1px currentColor;
  -webkit-transform: rotate(-22.5deg);
  transform: rotate(-22.5deg);
}
