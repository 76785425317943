.app {
  text-align: center;
}

.ui.container.app-bar {
  background-color: #fdfdfd;
  min-width: 100%;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  box-shadow: 5px 4px 4px 0px #000000;
  margin-top: 3vh;
}

.ui.container.app-bar:hover {
  background-color: #e2e2e2;
}

.app-name {
  margin-left: 1vw;
}

.app-body {
  background-color: #2e3d6b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-detail-grid {
  background-color: #2e3d6b;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wectl-headers {
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #ffffff;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}

p:first-child.wectl-spacing {
  margin-top: 4vh;
}

p.wectl-spacing {
  margin-top: 4vh;
}

.stuck {
  position: fixed;
  left: 3vw;
  top: 2vh;
}

.cs-button {
  margin: 4px !important;
  min-width: 95%;
}

.cs-button.active {
  margin: 4px;
  background-color: #025999 !important;
}

.cs-box {
  background-color: #0178cc;
  border-radius: 6px;
  margin-top: 11vh;
  max-width: 100%;
}

.ui.input.input-override {
  top: 80px;
  width: 100%;
}

.error-notice {
  min-width: 63.53vw;
}

.ui.secondary.menu.wectl-menu {
  margin-bottom: 0.5em;
}

.ui.button.confirm-modal {
  float: right;
  margin-top: 3vh;
  min-width: 150px;
}
.ui.button.confirm-modal.no {
  float: left;
}
