.environment-variables-view .eye {
  width: 30px;
}

.environment-variables-view .key {
  width: 30px;
  float: left;
  top: 0.5vh;
  opacity: 0.8;
}

.environment-variables-view .keyName {
  padding-left: 1vw;
}

.environment-variables-view .ui.image.plus {
  margin-left: 0.3vw;
  top: -0.5vh;
  width: 25px;
  display: inline-block;
}

.ui.button.env-edit-button {
  margin-top: 1vh;
  float: right;
}

.environment-variables-view .restart-message {
  color: red;
  font-style: italic;
  font-size: smaller;
}

.environment-variables-view .value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.environment-variables-view .value:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.environment-variables-view .loading .value:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

.environment-variables-view .loading .value > span {
  display: none;
}

.environment-variables-view .remove-env-var {
  float: right;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
