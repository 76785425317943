.cronjobs-elk-link-view .scope-type {
  margin-bottom: 1rem;
}

.cronjobs-elk-link-view .button-section {
  margin-top: 1rem;
}

.cronjobs-elk-link-view .cronjob-entry .job-state {
  display: inline-block;
  font-size: 0.7rem;
  line-height: 1rem;
  border: 1px solid;
  padding: 2px;
  text-transform: uppercase;
  margin-left: 14%;
}

.cronjobs-elk-link-view .cronjob-entry .job-state.success {
  border-color: #88cf67;
  color: #88cf67;
}

.cronjobs-elk-link-view .cronjob-entry .job-state.failed {
  border-color: #bd5c58;
  color: #bd5c58;
}

@keyframes elk-link-view-job-status-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.cronjobs-elk-link-view .cronjob-entry .job-state.active {
  border-color: #378ec8;
  color: #378ec8;
  animation: elk-link-view-job-status-animation 1.8s infinite;
}

.cronjobs-elk-link-view .cronjob-entry .job-state.unknown {
  border-color: #4d5052;
  color: #4d5052;
}
