.external-dashboards-prompt {
  font-size: 0.6875rem;
  color: #0178cc;
  margin-right: 1vw;
  border: solid #0178cc;
  border-width: 0 0 1px;
  padding: 2px 3px;
  cursor: pointer;
}

.external-dashboards-popup .how-to-enable-dashboards {
  font-size: 0.65rem;
  color: #555;
}

.external-dashboards-popup .weapm-service-name {
  font-size: 0.8rem;
}
